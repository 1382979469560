import { Component, OnInit, ViewChild, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { GoogleApiService } from 'app/shared/providers/google-api.service';
import { Address } from '@app-models/address.model';

@Component({
  selector: 'app-autocomplete-address',
  templateUrl: './autocomplete-address.component.html',
  styleUrls: ['./autocomplete-address.component.scss'],
})
export class AutocompleteAddressComponent implements OnInit {
  ac: { data: any[]; loading: boolean; timeout: any; done: boolean; searchStr: string } = {
    data: [],
    loading: false,
    timeout: null,
    done: false,
    searchStr: null,
  };
  street1: string;
  @Input() value: Address;
  @Output() selected: EventEmitter<Object> = new EventEmitter();
  @ViewChild('acList', { static: true }) acList;
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    if (this.acList) {
      const clickedInside = this.acList.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.ac.data = [];
        this.ac.loading = false;
      }
    }
  }

  constructor(private _googleApiService: GoogleApiService) {}

  ngOnInit() {
    if (this.value && this.value.street1) {
      this.street1 = this.value.formatted;
    }
  }
  googleAc(value) {
    if (value && !this.ac.done) {
      this.ac.loading = true;
      if (this.ac.timeout) {
        clearTimeout(this.ac.timeout);
      }
      this.ac.timeout = setTimeout(async () => {
        try {
          this.value = null;
          this.ac.data = await this._googleApiService.getAddressPredictions(value);
          this.ac.loading = false;
        } catch (e) {
          console.log(e);
          this.ac.loading = false;
        }
      }, 500);
    }
  }
  async acSelect(item) {
    this.ac.data = [];
    try {
      const place = await this._googleApiService.getPlaceById(item.place_id);
      const value = this._googleApiService.formatPlaceAddress(place);
      //this.street1 = value.formatted;
      this.street1 = value.label;
      //delete value.formatted;
      delete value.label;
      this.selected.emit(value);
      this.ac.done = true;
      this.value = value;
    } catch (e) {
      console.log(e);
      this.ac.done = true;
    }
  }
  async selectAdresssNotFound() {
    let value;
    if (this.value) {
      return this.value;
    } else {
      // pas d'adresse
      if (this.street1 && this.street1 !== '') {
        // geocode coordinates
        /*try {
          const geocoded = await this._googleApiService.geocodeAddress(
            this.street1,
            ""
          );
          if (geocoded) {
            const geocoded_address = this._googleApiService.formatPlaceAddress(geocoded);
            value = new Address(geocoded_address);
            let street1 = this.street1;
            for (const str of value.label.split(' ')) {
              street1 = street1.toLocaleLowerCase().replace(str.replace(/(^,)|(,$)/g, "").toLocaleLowerCase(),  '')
            }
            value.street1 = street1;
          } else {
            value = new Address({street1: this.street1});
          }
        } catch (e) {
          // no result
        }*/
        value = new Address({ street1: this.street1 });
      }
    }
    this.selected.emit(value);
    return value;
  }
}
