import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  private formValue: string;
  public text: string;

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
    this.text = this.data ? this.data : 'Êtes-vous sûr de vouloir supprimer cet élément ?';
  }

  delete(): void {
    this.dialogRef.close(true);
  }
  close(): void {
    this.dialogRef.close(false);
  }
}
