import { BaseModel } from '../base.model';
import { ValeurLexique } from '../valeurLexique.model';

export class TypeEscalier extends BaseModel {
  type: ValeurLexique;
  largeurMax: Number;

  constructor(arg?: any) {
    super(arg);
  }
}
