import { BaseModel } from '../base.model';
import { ValeurLexique } from '../valeurLexique.model';

export class LocalTechnique extends BaseModel {
  type: ValeurLexique;
  distance: Number;
  profondeurMax: Number;
  constructor(arg?: any) {
    super(arg);
  }
}
