import { BaseModel } from './base.model';
import { CategorieArticle } from './categorie-article.model';
import { jsonIgnore } from 'json-ignore';

export class Article extends BaseModel {
  fournisseur: string;
  designation: string;
  reference?: string;
  codeBarre?: string;
  @jsonIgnore()
  compteId: string;

  constructor(arg?: any) {
    super(arg);
    if (arg) {
    }
  }
}
export namespace Article {
  export enum TypeArticleEnum {
    materiaux = 'materiaux',
    mainDOeuvre = 'mainDOeuvre',
  }
  export enum UniteArticleEnum {
    forfait = 'forfait',
  }
  export const uniteValues = [{ _id: Article.UniteArticleEnum.forfait, libelle: 'Forfait' }];
  export const typeValues = [
    { _id: Article.TypeArticleEnum.mainDOeuvre, libelle: "Main d'oeuvre" },
    { _id: Article.TypeArticleEnum.materiaux, libelle: 'Matériaux' },
  ];
}
