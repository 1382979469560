import { Component, Inject, OnInit } from '@angular/core';
import { User } from '@app-models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@app-services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from 'app/shared/providers/session.service';
import { ToasterService } from '@app-providers/toaster.service';
import { FormHelperService } from '@app-providers/form-helper.service';
import { Observable, Subject, concat, of } from 'rxjs';
import { tap, switchMap, catchError, distinctUntilChanged } from 'rxjs/operators';
import { DemandeService } from '@app-services/demande.service';
import { Demande } from '@app-models/demande.model';
@Component({
  selector: 'app-demande-edit',
  templateUrl: './demande-edit.component.html',
  styleUrls: ['./demande-edit.component.scss'],
})
export class DemandeEditComponent implements OnInit {
  isLoading: Boolean = false;
  form: FormGroup;
  public formSubmitAttempt: boolean;
  typeValues = Demande.kindValues;
  // client autocomplete
  selectedClients: User[];
  client$: Observable<User[]>;
  clientLoading = false;
  clientInput$ = new Subject<string>();
  constructor(
    public dialogRef: MatDialogRef<DemandeEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private fb: FormBuilder,
    private _toasterService: ToasterService,
    public session: SessionService,
    private _formHelperService: FormHelperService,
    private _demandeService: DemandeService,
    private _userService: UserService,
  ) {
    this.isLoading = false;
  }
  async ngOnInit() {
    let config: any = {
      type: [null || '', Validators.required],
      description: [null],
    };
    if (!this.data || !this.data._id) {
      config.client = [null || '', Validators.required];
    }
    this.form = this.fb.group(config);
    this.form.reset();
    // get client role
    this.loadClient();
  }

  async submit() {
    this.formSubmitAttempt = true;
    if (this.form.valid) {
      try {
        this.isLoading = true;
        let { client, description, type } = this.form.value;
        if (this.data && this.data._id) {
          client = this.data;
        } else {
          client = client[0];
        }
        const p = new Demande({
          client,
          description,
          type,
          statut: Demande.StatutEnum.nouveau,
          numero: Date.now(),
          date: new Date().toISOString(), // #TO REMOVE
        });
        const result = await this._demandeService.add(p).toPromise();
        this.formSubmitAttempt = false;
        // Reset Fields
        this.form.reset();
        this.dialogRef.close(result);
        this.isLoading = false;
      } catch (e) {
        this._toasterService.error(e.message);
        this.formSubmitAttempt = false;
        this.isLoading = false;
      }
    } else {
      this._formHelperService.validateAllFormFields(this.form);
      this._formHelperService.scrollToError();
      this.formSubmitAttempt = false;
    }
  }

  isFieldValid(field: any) {
    return this._formHelperService.isFieldValid(this.form, field);
  }
  /**
   * [Description] Set error class on form based on Form and field validation
   * @param {FormGroup} form
   * @param {string} field
   */
  displayFieldCss(form: FormGroup, field: string) {
    return {
      'has-error': this._formHelperService.isFieldValid(form, field),
      'has-feedback': this._formHelperService.isFieldValid(form, field),
    };
  }

  reset() {
    this.form.reset();
  }
  trackByFn(item: User) {
    return item._id;
  }
  compareWith(a, b) {
    return a._id === b._id;
  }
  private loadClient() {
    const getQuery = (term) => {
      let queryString = "((substringof('" + term + "',nom) eq true)";
      queryString += " or (substringof('" + term + "',prenom) eq true))";
      return queryString;
    };
    this.client$ = concat(
      of([]), // default items
      this.clientInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.clientLoading = true)),
        switchMap((term) =>
          this._userService.listClients(1, 0, getQuery(term)).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.clientLoading = false)),
          ),
        ),
      ),
    );
  }
}
