import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Intervention } from '@app-models/intervention.model';
import { LocaleConfig } from 'ngx-daterangepicker-material';
moment.locale('fr');

@Component({
  selector: 'app-date-modal',
  templateUrl: './date-modal.component.html',
  styleUrls: ['./date-modal.component.scss'],
})
export class DateModalComponent {
  isRange: boolean;
  startDate;
  endDate;
  hour: any;
  locale: LocaleConfig = {
    applyLabel: 'Enregistrer',
    format: 'DD/MM/YYYY',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
  };
  constructor(public dialogRef: MatDialogRef<DateModalComponent>, @Inject(MAT_DIALOG_DATA) public data: Intervention) {
    this.isRange = false;
    if (!this.data.date && this.data.dateDebutPlanification) {
      this.isRange = true;
      this.startDate = moment(this.data.dateDebutPlanification);
      this.endDate = moment(this.data.dateFinPlanification);
    } else if (this.data.date && !this.data.dateDebutPlanification) {
      this.isRange = false;
      this.startDate = moment(this.data.date);
      this.endDate = moment(this.data.date);
      this.hour = { hour: moment(this.data.date).hour(), minute: moment(this.data.date).minute() };
    } else {
      this.startDate = moment();
      this.endDate = moment();
    }
  }

  toggle() {
    this.startDate = moment();
    this.endDate = moment();
  }
  close(): void {
    this.dialogRef.close(false);
  }
  choosedDate(date) {
    if (this.isRange) {
      this.dialogRef.close({
        dateDebutPlanification: date.startDate,
        dateFinPlanification: date.endDate,
        date: null,
      });
    } else {
      this.startDate = date.startDate;
    }
  }
  save() {
    if (!this.hour) {
      // date plan
      this.dialogRef.close({
        date: null,
        dateDebutPlanification: moment(this.startDate).set({ hour: 0, minute: 0 }),
        dateFinPlanification: moment(this.startDate).set({ hour: 23, minute: 59 }),
      });
    } else {
      const { hour, minute } = this.hour;
      this.startDate.set({ hour, minute });
      this.dialogRef.close({
        date: this.startDate,
        dateDebutPlanification: null,
        dateFinPlanification: null,
      });
    }
  }
}
