import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpService } from '@app-providers/http.service';
import { Article } from '@app-models/article.model';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends BaseService<Article> {
  constructor(protected _httpService: HttpService) {
    super(_httpService, Article, 'article');
  }
}
