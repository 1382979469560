import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { User } from '@app-models/user.model';
import { HttpService } from '@app-providers/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Materiel } from '@app-models/materiel.model';
import { Intervention } from '@app-models/intervention.model';
import { Historique } from '@app-models/historique.model';
import { Fichier } from '@app-models/fichier.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<User> {
  customerToAdd: User;
  constructor(protected _httpService: HttpService) {
    super(_httpService, User, 'utilisateur');
  }

  public listTechniciens(
    page: number = 1,
    perPage: number = 10,
    query?: any,
    order?: string,
    select?: string,
    isAscendant?: boolean,
  ): Observable<User[]> {
    const $skip = ((page - 1) * perPage).toString();
    const $top = perPage.toString();
    const $query = { $skip, $top };
    if (query) {
      $query['$filter'] = query;
    }
    if (order) {
      $query['$orderby'] = order;
    }
    if (select) {
      $query['$select'] = select;
    }

    return this.httpService.get(`${this.path}/techniciens`, $query).pipe(
      map((x) => {
        this.totalCount.next(x.data.totalDocs);
        return x.data.docs.map((u: User) => {
          return new User(u);
        });
      }),
    );
  }
  public listClients(
    page: number = 1,
    perPage: number = 10,
    query?: any,
    order?: string,
    select?: string,
    isAscendant?: boolean,
  ): Observable<User[]> {
    const $skip = ((page - 1) * perPage).toString();
    const $top = perPage.toString();
    const $query = { $skip, $top };
    if (query) {
      $query['$filter'] = query;
    }
    if (order) {
      $query['$orderby'] = order;
    }
    if (select) {
      $query['$select'] = select;
    }

    return this.httpService.get(`${this.path}/clients`, $query).pipe(
      map((x) => {
        this.totalCount.next(x.data.totalDocs);
        return x.data.docs.map((u: User) => {
          return new User(u);
        });
      }),
    );
  }

  public listUtilisateurs(
    page: number = 1,
    perPage: number = 10,
    query?: any,
    order?: string,
    select?: string,
    isAscendant?: boolean,
  ): Observable<User[]> {
    const $skip = ((page - 1) * perPage).toString();
    const $top = perPage.toString();
    const $query = { $skip, $top };
    if (query) {
      $query['$filter'] = query;
    }
    if (order) {
      $query['$orderby'] = order;
    }
    if (select) {
      $query['$select'] = select;
    }

    return this.httpService.get(`${this.path}/utilisateurs`, $query).pipe(
      map((x) => {
        this.totalCount.next(x.data.totalDocs);
        return x.data.docs.map((u: User) => {
          return new User(u);
        });
      }),
    );
  }
  updateProfile(item: User): Observable<User> {
    return this.httpService.patch(`updateProfile`, item.prepareForSending());
  }
  public updateCustomerToAdd(arg) {
    for (let key in arg) {
      this.customerToAdd.extra[key] = arg[key];
    }
  }
  addCurrentCustomer(): Observable<User> {
    return this.add(this.customerToAdd);
  }
  public listMateriels(
    userId: string,
    page: number = 1,
    perPage: number = 10,
    query?: any,
    order?: string,
    select?: string,
    isAscendant?: boolean,
  ): Observable<Materiel[]> {
    const $skip = ((page - 1) * perPage).toString();
    const $top = perPage.toString();
    const $query = { $skip, $top };
    if (query) {
      $query['$filter'] = query;
    }
    if (order) {
      $query['$orderby'] = order;
    }
    if (select) {
      $query['$select'] = select;
    }

    return this.httpService.get(`${this.path}/${userId}/materiel`, $query).pipe(
      map((x) => {
        this.totalCount.next(x.data.totalDocs);
        return x.data.docs.map((u: Materiel) => {
          return new Materiel(u);
        });
      }),
    );
  }
  public listImages(
    userId: string,
    page: number = 1,
    perPage: number = 10,
    query?: any,
    order?: string,
    select?: string,
    isAscendant?: boolean,
  ): Observable<Historique[]> {
    const $skip = ((page - 1) * perPage).toString();
    const $top = perPage.toString();
    const $query = { $skip, $top };
    if (query) {
      $query['$filter'] = query;
    }
    if (order) {
      $query['$orderby'] = order;
    }
    if (select) {
      $query['$select'] = select;
    }

    return this.httpService.get(`${this.path}/${userId}/images`, $query).pipe(
      map((x) => {
        this.totalCount.next(x.data.total);
        return x.data.docs.map((u: Fichier) => {
          return new Fichier(u, u.folder || 'intervention');
        });
      }),
    );
  }

    /**
   * Delete item from API
   *
   * @param {Fichier} item
   * @returns {Observable<T>}
   * @memberof BaseService
   */
    public deleteImage(item: any) {
      return this.httpService.delete(`${this.path}/deleteImage/${item._id}`)
    }

  public listHistorique(
    userId: string,
    page: number = 1,
    perPage: number = 10,
    query?: any,
    order?: string,
    select?: string,
    isAscendant?: boolean,
  ): Observable<Historique[]> {
    const $skip = ((page - 1) * perPage).toString();
    const $top = perPage.toString();
    const $query = { $skip, $top };
    if (query) {
      $query['$filter'] = query;
    }
    if (order) {
      $query['$orderby'] = order;
    }
    if (select) {
      $query['$select'] = select;
    }

    return this.httpService.get(`${this.path}/${userId}/historique`, $query).pipe(
      map((x) => {
        this.totalCount.next(x.data.total);
        return x.data.docs.map((u: Historique) => {
          return new Historique(u);
        });
      }),
    );
  }
}
