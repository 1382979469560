import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { User } from '@app-models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Fichier } from '@app-models/fichier.model';
const jwt = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private checkIsInProgress = false;
  public user: User = {} as User;
  sousdomaine: string;

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  libelleRole: string;
  autorisations: string[];

  constructor(private _httpService: HttpService, private _localStorageService: LocalStorageService) {}

  getSessionStatus(): Observable<boolean> {
    return Observable.create(async (observer) => {
      try {
        const token: string = this._localStorageService.get('token');

        if (!token) {
          this.logout();
          observer.next(false);
        } else {
          await this.initSession(token);
          observer.next(true);
        }
      } catch (e) {
        observer.next(false);
      }
      observer.complete();
    });
  }

  async initSession(token: string) {
    this._localStorageService.set('token', token);
    try {
      // Check if token is expired
      if (jwt.isTokenExpired(token)) {
        await this.regenerate(token).toPromise();
        return;
      }

      const decodedToken = jwt.decodeToken(token);
      this.autorisations = decodedToken.role.autorisations;
      this.libelleRole = decodedToken.role.libelle;
      // Retrieve user data
      let user;
      if (!this.user || !this.user._id) {
        const data = await this._httpService.get(`utilisateur/me`).toPromise();
        user = new User(data.data);
      } else {
        user = this.user;
      }
      let compte;
      if (decodedToken.compteId) {
        compte = await this._httpService.get(`compte/${decodedToken.compteId}`).toPromise();
      }
      // Set current user data into observable
      this.setAuth(user, compte ? compte.data : {});

      this.programNextRegenerate();
    } catch (e) {
      console.error(e);
    }
  }

  login(user: any): Observable<any> {
    return this._httpService.post('login', user).pipe(
      tap((sessionUser) => {
        this.initSession(sessionUser.data);
      }),
    );
  }

  forgotPassword(data: any): Observable<any> {
    return this._httpService.post('/forgotPassword', data);
  }

  public logout() {
    const sousdomaine = this._localStorageService.get('sousdomaine');
    this._localStorageService.removeAll();
    this._localStorageService.set('sousdomaine', sousdomaine);
    this.purgeAuth();
  }

  regenerate(token: string): Observable<any> {
    return Observable.create(async (observer) => {
      try {
        const datas = await this._httpService.get('/regenerate?token=' + token).toPromise();
        await this.initSession(datas.token);
        observer.next(datas);
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
    });
  }

  setAuth(user: User, compte?: any) {
    // Set current user
    this.user = user;
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Set current user to an empty object
    this.user = undefined;
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  private programNextRegenerate = () => {
    if (this.checkIsInProgress === true) {
      return;
    }
    const token = this._localStorageService.get('token');
    const expirationDate = moment(jwt.getTokenExpirationDate(token)).subtract(10, 'minutes');
    this.checkIsInProgress = true;
    let delay = (expirationDate.valueOf() - moment().valueOf()) / 1000;

    if (delay < 10) {
      delay = 10;
    }

    setTimeout(async () => {
      try {
        // await this.regenerate(token);
      } catch (e) {
        console.log(e);
      }

      this.checkIsInProgress = false;

      this.programNextRegenerate();
    }, delay * 1000);
  };
  async configSousDomaine(sousdomaine?) {
    try {
      if (!sousdomaine) {
        // check LS
        sousdomaine = this._localStorageService.get('sousdomaine');
        if (!sousdomaine) {
          return;
        }
      }
      this.sousdomaine = sousdomaine;
      const i = await this._httpService.get(`compte/getColorAndLogo`, {}, { sousdomaine }).toPromise();
      this._localStorageService.set('sousdomaine', sousdomaine);
    } catch (e) {}
  }
}
