import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './button/button.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { AutocompleteAddressComponent } from './autocomplete-address/autocomplete-address.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SelectModeleModalComponent } from './select-modele-modal/select-modele-modal.component';
import { SelectHourModalComponent } from './select-hour-modal/select-hour-modal.component';
import { SelectUserModalComponent } from './select-user-modal/select-user-modal.component';
import { EditMaterielModalComponent } from './edit-materiel-modal/edit-materiel-modal.component';
import { NgbTimepickerModule } from 'app/components/timepicker/timepicker.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from '@vpetrusevici/ngx-chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { DemandeEditComponent } from 'app/pages/demande/demande-edit/demande-edit.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DateModalComponent } from './date-modal/date-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgbTimepickerModule,
    NgSelectModule,
    TagInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatCheckboxModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  declarations: [
    ButtonComponent,
    DeleteModalComponent,
    AutocompleteAddressComponent,
    FileUploaderComponent,
    SelectModeleModalComponent,
    SelectHourModalComponent,
    SelectUserModalComponent,
    EditMaterielModalComponent,
    DemandeEditComponent,
    DateModalComponent,
    ConfirmModalComponent,
  ],
  exports: [
    ButtonComponent,
    DeleteModalComponent,
    AutocompleteAddressComponent,
    FileUploaderComponent,
    ConfirmModalComponent,
  ],
  entryComponents: [
    DeleteModalComponent,
    SelectModeleModalComponent,
    SelectHourModalComponent,
    SelectUserModalComponent,
    EditMaterielModalComponent,
    DemandeEditComponent,
    DateModalComponent,
    ConfirmModalComponent,
  ],
})
export class ComponentsModule {}
