import { BaseModel } from '../base.model';
import { ValeurLexique } from '../valeurLexique.model';

export class CoffreVoletRoulant extends BaseModel {
  type: ValeurLexique;
  profondeurMax: Number;

  constructor(arg?: any) {
    super(arg);

    if (arg) {
    }
  }
}
