import { BaseModel } from './base.model';
import { jsonIgnore } from 'json-ignore';
import { Fichier } from './fichier.model';
import { Address } from './address.model';
import * as moment from 'moment';

export class Historique extends BaseModel {
  numero: string;
  titre: string;
  type: Historique.TypeEnum;
  statut: Historique.StatutEnum;
  compteId: string;
  @jsonIgnore()
  get statutLabel(): string {
    return Historique.statusValues.find((i) => i.id === this.statut).name;
  }
  @jsonIgnore()
  get statutColor(): string {
    return Historique.statusValues.find((i) => i.id === this.statut).color;
  }
  @jsonIgnore()
  get typeLabel(): string {
    return Historique.kindValues.find((i) => i.id === this.type).name;
  }
  constructor(arg?: any) {
    super(arg);
  }
}
export namespace Historique {
  export enum TypeEnum {
    chantier = 'chantier',
    depannage = 'depannage',
    entretien = 'entretien',
    devis = 'devis',
    autre = 'autre',
  }
  export const kindValues = [
    { id: Historique.TypeEnum.chantier, name: 'Chantier' },
    { id: Historique.TypeEnum.entretien, name: 'Entretien' },
    { id: Historique.TypeEnum.depannage, name: 'Dépannage' },
    { id: Historique.TypeEnum.devis, name: 'Devis' },
    { id: Historique.TypeEnum.autre, name: 'Autre' },
  ];
  export enum StatutEnum {
    nouveau = 'nouveau',
    annule = 'annule',
    termine = 'termine',
    transformeIntervention = 'transformeIntervention',
    aEffectuer = 'aEffectuer',
    enCours = 'enCours',
    aFacturer = 'aFacturer',
    facture = 'facture',
    regle = 'regle',
  }
  export const statusValues = [
    { id: Historique.StatutEnum.nouveau, name: 'Nouveau', color: '#4a90e2' },
    { id: Historique.StatutEnum.annule, name: 'Annulé', color: 'red' },
    { id: Historique.StatutEnum.transformeIntervention, name: 'Transformé en intervention', color: '#f97a4a' },
    { id: Historique.StatutEnum.termine, name: 'Terminé', color: '#96e83b' },
    { id: Historique.StatutEnum.aEffectuer, name: 'A effectuer', color: '#4a90e2' },
    { id: Historique.StatutEnum.enCours, name: 'En cours', color: 'red' },
    { id: Historique.StatutEnum.aFacturer, name: 'A facturer', color: '#96e83b' },
    { id: Historique.StatutEnum.facture, name: 'Facturé', color: '#96e83b' },
    { id: Historique.StatutEnum.regle, name: 'Reglé', color: '#96e83b' },
  ];
}
