import { jsonIgnore, jsonIgnoreReplacer } from 'json-ignore';
import { BaseModel } from './base.model';
import { Dimension } from './piscineElementModele/dimension.model';
import { Profondeur } from './piscineElementModele/profondeur.model';
import { TypeEscalier } from './piscineElementModele/typeEscalier.model';
import { LocalTechnique } from './piscineElementModele/localTechnique.model';
import { CoffreVoletRoulant } from './piscineElementModele/coffreVoletRoulant.model';
import { ValeurLexique } from './valeurLexique.model';

export enum FormeEnum {
  rectangle = 'rectangle',
  carree = 'carre',
  lazy = 'lazy',
  haricot = 'haricot',
  complexe = 'complexe',
  libre = 'libre',
}

export class Piscine extends BaseModel {
  forme: FormeEnum;
  dimension: Dimension;
  profondeur: Profondeur;
  typeEscalier: TypeEscalier;
  localTechnique: LocalTechnique;
  coffreVoletRoulant: CoffreVoletRoulant;
  lexiques: ValeurLexique[];
  volumeBassin: string;
  sel: string;

  get formeLabel() {
    return PiscineFormeValeurs.find((i) => i._id === this.forme)
      ? PiscineFormeValeurs.find((i) => i._id === this.forme).label
      : 'N/A';
  }
  constructor(arg?: any) {
    super(arg);

    if (arg) {
      if (arg.profondeur) {
        this.profondeur = new Profondeur(arg.profondeur);
      }
      if (arg.typeEscalier) {
        this.typeEscalier = new TypeEscalier(arg.typeEscalier);
      }
      if (arg.localTechnique) {
        this.localTechnique = new LocalTechnique(arg.localTechnique);
      }
      if (arg.coffreVoletRoulant) {
        this.coffreVoletRoulant = new CoffreVoletRoulant(arg.coffreVoletRoulant);
      }
      if (arg.lexiques) {
        this.lexiques = BaseModel.initModel(arg.lexiques, ValeurLexique);
      }
    }
  }

  prepareForSending() {
    const copy: any = {};
    try {
      const data = JSON.parse(JSON.stringify(this, jsonIgnoreReplacer));
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (data[key] === null) {
          } else {
            if (key === 'lexiques') {
              copy[key] = this[key].map((i) => i.prepareForSending());
            } else {
              copy[key] = data[key];
            }
          }
        }
      }
    } catch (e) {}
    return copy;
  }
}

export const PiscineFormeValeurs = [
  { _id: FormeEnum.rectangle, label: 'Rectangle', img: 'assets/img/shape-rectangle.png' },
  { _id: FormeEnum.carree, label: 'Carrée', img: 'assets/img/shape-carre.png' },
  { _id: FormeEnum.lazy, label: 'Lazy', img: 'assets/img/shape-lazy.png' },
  { _id: FormeEnum.haricot, label: 'Haricot', img: 'assets/img/shape-haricot.png' },
  { _id: FormeEnum.complexe, label: 'Complexe', img: 'assets/img/shape-complexe.png' },
  { _id: FormeEnum.libre, label: 'Libre', img: 'assets/img/shape-free.png' },
];

export const PiscineLexiques = [
  new ValeurLexique({ categorie: 'typeFiltration', valeur: null }),
  new ValeurLexique({ categorie: 'terrasse', valeur: null }),
  new ValeurLexique({ categorie: 'traitement', valeur: null }),
  new ValeurLexique({ categorie: 'choixRevetement', valeur: null }),
  new ValeurLexique({ categorie: 'securite', valeur: null }),
  new ValeurLexique({ categorie: 'baches', valeur: null }),
  new ValeurLexique({ categorie: 'option', valeur: null }),
];
