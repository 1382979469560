import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Demande } from '@app-models/demande.model';
import { HttpService } from '@app-providers/http.service';

@Injectable({
  providedIn: 'root',
})
export class DemandeService extends BaseService<Demande> {
  constructor(protected _httpService: HttpService) {
    super(_httpService, Demande, 'demande');
  }
  formulaire(data) {
    return new Promise((resolve, reject) => {
      this.httpService.post(`${this.path}/formulaireClient`, data).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
}
