import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@app-guards/module-import.guard';
import { BasicAuthInterceptor } from '@app-providers/auth.interceptor';
import { CoreRoutingModule } from './core-routing.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { GoogleApiService } from '@app-providers/google-api.service';
import { ComponentsModule } from 'app/components/components.module';
import { RoleService } from '@app-services/role.service';

@NgModule({
  declarations: [AdminLayoutComponent, AuthLayoutComponent],
  imports: [CommonModule, CoreRoutingModule, HttpClientModule, SidebarModule, ComponentsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    GoogleApiService,
    RoleService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
