import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DeleteModalComponent } from 'app/components/delete-modal/delete-modal.component';
import { environment } from 'environments/environment';
import { ConfirmModalComponent } from 'app/components/confirm-modal/confirm-modal.component';

declare var $: any;
declare var swal: any;

@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor(private _location: Location, private _domSanitizationService: DomSanitizer, private dialog: MatDialog) {}

  confirm(text?: string): Promise<boolean> {
    if (!text) {
      text = 'Voulez-vous vraiment continuer à réaliser cette action?';
    }
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DeleteModalComponent, {
        width: '450px',
        data: text,
      });
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }
  confirmChange(text?: string): Promise<boolean> {
    if (!text) {
      text = 'Voulez-vous vraiment continuer à réaliser cette action?';
    }
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '450px',
        data: text,
      });
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }
  popupSuccess(message?) {
    return swal({
      title: 'Succès!',
      text: message || 'Traitement réussi.',
      type: 'success',
      confirmButtonClass: 'btn btn-success',
      buttonsStyling: false,
    });
  }
  back() {
    return this._location.back();
  }

  getfile(path, folder?) {
    return this._domSanitizationService.bypassSecurityTrustResourceUrl(
      environment.DATA_ENDPOINT + path + (folder ? '?folder=' + folder : ''),
    );
  }
}
