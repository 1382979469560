import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@app-models/user.model';
import { FormHelperService } from '@app-providers/form-helper.service';
import { Observable, Subject, concat, of } from 'rxjs';
import { tap, switchMap, catchError, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from '@app-services/user.service';
@Component({
  selector: 'app-select-user-modal',
  templateUrl: './select-user-modal.component.html',
  styleUrls: ['./select-user-modal.component.scss'],
})
export class SelectUserModalComponent implements OnInit {
  public editMode = false;
  public isChangePassword = false;
  public form: FormGroup;
  public formSubmitAttempt: boolean;
  user$: Observable<User[]>;
  userLoading = false;
  userInput$ = new Subject<string>();
  users: User[] = [];
  isLoading: boolean;
  isMultiple: boolean;
  maxSelectedItems: number;
  constructor(
    private _formHelperService: FormHelperService,
    private _dialogRef: MatDialogRef<SelectUserModalComponent>,
    private _userService: UserService,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.isLoading = false;
    this.isMultiple = false;
    this.maxSelectedItems = 1;
  }

  async ngOnInit() {
    if (this.data.key === 'equipe') {
      this.isMultiple = true;
      this.maxSelectedItems = 10;
      // disable responsable
    }
    this.form = this._fb.group({
      user: [this.data.value ? (this.isMultiple ? this.data.value : [this.data.value]) : null],
    });
    this.loadUser();
  }

  isFieldValid(field: any) {
    return this._formHelperService.isFieldValid(this.form, field);
  }

  submit() {
    this.form.updateValueAndValidity();
    this.formSubmitAttempt = true;
    if (this.form.valid) {
      const value = this.isMultiple ? this.form.value.user : this.form.value.user[0];
      if (!value && this.isMultiple) {
        this._dialogRef.close([]);
      } else if (!value && !this.isMultiple) {
        this._dialogRef.close(null);
      } else {
        this._dialogRef.close(value);
      }
    } else if (!this.isMultiple) {
      this._dialogRef.close(null);
    } else {
      this._formHelperService.validateAllFormFields(this.form);
      this.formSubmitAttempt = false;
    }
  }

  close(): void {
    this._dialogRef.close(false);
  }

  /**
   * [Description] Set error class on form based on Form and field validation
   * @param {FormGroup} form
   * @param {string} field
   */
  displayFieldCss(form: FormGroup, field: string) {
    return {
      'has-error': this._formHelperService.isFieldValid(form, field),
      'has-feedback': this._formHelperService.isFieldValid(form, field),
    };
  }
  trackByFn(item: User) {
    return item._id;
  }
  compareWith(a, b) {
    return a._id === b._id;
  }
  private loadUser() {
    const getQuery = (term) => {
      let queryString = "((substringof('" + term + "',nom) eq true)";
      queryString += " or (substringof('" + term + "',prenom) eq true))";
      // cas equipe
      if (this.data.key === 'equipe') {
        if (this.data.intervention && this.data.intervention.responsable) {
          queryString += " and (email ne '" + this.data.intervention.responsable.email + "')";
        }
      }
      // cas repos
      if (this.data.key === 'responsable') {
        if (this.data.intervention && this.data.intervention.equipe && this.data.intervention.equipe.length) {
          const query = this.data.intervention.equipe.map((i) => "(email ne '" + i.email + "')");
          queryString += ' and (' + query.join(' and ') + ')';
        }
      }
      return queryString;
    };
    this.user$ = concat(
      of([]), // default items
      this.userInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.userLoading = true)),
        switchMap((term) =>
          this._userService.listUtilisateurs(1, 0, getQuery(term)).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.userLoading = false)),
          ),
        ),
      ),
    );
  }
}
