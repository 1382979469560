import { BaseModel } from './base.model';
import { jsonIgnore } from 'json-ignore';

export class Role extends BaseModel {
  libelle: string;
  autorisations: string[] = [];
  constructor(arg?: any) {
    super(arg);
  }
}
