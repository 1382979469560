import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Modele } from '@app-models/modele.model';
import { HttpService } from '@app-providers/http.service';

@Injectable({
  providedIn: 'root',
})
export class ModeleService extends BaseService<Modele> {
  constructor(protected _httpService: HttpService) {
    super(_httpService, Modele, 'modele');
  }
}
