import { Component, Inject, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router } from '@angular/router';
import { SessionService } from '@app-providers/session.service';
import { User } from '@app-models/user.model';
import { LocalStorageService } from '@app-providers/local-storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { inject } from '@angular/core/testing';

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  customIconType?: string;
  collapse?: string;
  children?: ChildrenItems[];
  autorisation: string | string[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  autorisation: string | string[];
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/customer',
    title: 'Clients',
    type: 'link',
    icontype: 'icon-people',
    autorisation: ['clients.listing', 'clients.creation'],
  },
  {
    path: '/demande',
    title: 'Demandes',
    type: 'link',
    icontype: 'icon-bubble',
    autorisation: ['clients.listing', 'clients.creation'],
  },
  {
    path: '/intervention',
    title: 'Interventions',
    type: 'link',
    icontype: 'icon-wrench',
    autorisation: ['interventions.listing', 'interventions.creation'],
  },
  {
    path: '/materiel',
    title: 'Matériel',
    type: 'link',
    icontype: 'icon-basket',
    autorisation: ['materiels.listing', 'materiels.creation'],
  },
  {
    path: '/modele',
    title: 'Modèles',
    type: 'link',
    icontype: 'icon-layers',
    autorisation: ['modeles.listing', 'modeles.creation'],
  },
  /*{
    path: '/devis',
    title: 'Devis',
    type: 'link',
    icontype: 'pe-7s-menu',
    autorisation: ['clients.listing', 'clients.creation'],
  },
  {
    path: '/categories',
    title: 'Catégories d\'article',
    type: 'link',
    icontype: 'pe-7s-box2',
    autorisation: ['clients.listing', 'clients.creation'],
  },
  {
    path: '/article',
    title: 'Articles',
    type: 'link',
    icontype: 'pe-7s-star',
    autorisation: ['clients.listing', 'clients.creation'],
  },
  /*{
    path: '/nomenclature',
    title: 'Nomenclatures',
    type: 'link',
    icontype: 'pe-7s-star',
    autorisation: ['clients.listing', 'clients.creation'],
  },*/
  {
    path: '/article',
    title: 'Articles',
    type: 'link',
    icontype: 'pe-7s-star',
    autorisation: ['articles.listing', 'articles.creation'],
  },
  {
    path: '/users',
    title: 'Utilisateurs',
    type: 'link',
    icontype: 'icon-user',
    autorisation: ['utilisateurs.listing', 'utilisateurs.creation'],
  },
  {
    path: '/lexicals',
    title: 'Lexiques',
    type: 'link',
    icontype: 'icon-doc',
    autorisation: ['lexique.listing', 'lexiques.listing'],
  },
];
@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public ps: any;
  public currentUser: User = {} as User;
  public role: string[];
  logoPath: any;
  mobile_menu_visible: any = 0;
  public sidebarVisible: boolean;

  constructor(
    @Inject(SessionService) private _sessionService: SessionService,
    private sanitizer: DomSanitizer,
    private _router: Router,
  ) {
    this.sidebarVisible = false;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.role = this._sessionService.autorisations;
    this.currentUser = this._sessionService.user;
    // hack menu list
    this.menuItems = ROUTES;

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  logout() {
    this._sessionService.logout();
    this._router.navigate(['/auth', 'login']);
  }
  sidebarOpen() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('sidebar-mini');

    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    if (body.querySelectorAll('.main-panel')) {
      document.getElementsByClassName('main-panel')[0].appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);

    body.classList.add('sidebar-mini');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    this.sidebarVisible = false;
    body.classList.remove('sidebar-mini');
    // $('html').removeClass('nav-open');
    body.classList.remove('sidebar-mini');
    if ($layer) {
      $layer.remove();
    }
    this.mobile_menu_visible = 0;
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
}
