import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modele } from '@app-models/modele.model';
import { FormHelperService } from '@app-providers/form-helper.service';
import { Observable, Subject, concat, of } from 'rxjs';
import { tap, switchMap, catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { ModeleService } from '@app-services/modele.service';
@Component({
  selector: 'app-select-modele-modal',
  templateUrl: './select-modele-modal.component.html',
  styleUrls: ['./select-modele-modal.component.scss'],
})
export class SelectModeleModalComponent implements OnInit {
  selectedModele: Modele;
  modeles: Modele[];
  originalModeles: Modele[];
  modele: Modele;
  standard = Modele.standard;

  searchStr: string;
  isLoading: boolean;
  articleInput$ = new Subject<any>();
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger, static: true })
  inputAutoComplit: MatAutocompleteTrigger;

  constructor(
    private _dialogRef: MatDialogRef<SelectModeleModalComponent>,
    private _modeleService: ModeleService,
    @Inject(MAT_DIALOG_DATA) public data: Modele,
  ) {
    this.isLoading = false;
  }

  async ngOnInit() {
    this.loadModele();
    this.articleInput$.pipe(distinctUntilChanged()).subscribe((data: any) => {
      if (typeof data === 'string') {
        this.modeles = this.originalModeles.filter((i) => i.titre.toLowerCase().includes(data.toLowerCase()));
      } else {
        this.selectModele(data);
      }
    });
  }
  close(): void {
    this._dialogRef.close();
  }
  async loadModele() {
    try {
      this.isLoading = true;
      this.originalModeles = this.modeles = await this._modeleService.list(1, 100, null).toPromise();
      // this.modeles.unshift(this.standard);
      this.inputAutoComplit.openPanel();
      this.isLoading = false;
    } catch (e) {
      this.modeles = [];
      this.isLoading = false;
    }
  }
  selectModele(modele) {
    this._dialogRef.close(modele);
  }
  selectStandard() {
    this.articleInput$.next(this.standard);
  }
}
