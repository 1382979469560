import { jsonIgnore } from 'json-ignore';
import { BaseModel } from './base.model';
class Location extends BaseModel {
  type: string;
  coordinates: any[];
  constructor(data) {
    super(data);
  }
}

export class Address extends BaseModel {
  street1: string;
  @jsonIgnore()
  street2: string;
  postalCode: string;
  city: string;
  location: Location;
  @jsonIgnore()
  country: string;

  constructor(data?: any) {
    super(data);
    if (data) {
      if (data.location) {
        this.location = new Location(data.location);
      }
    }
  }

  @jsonIgnore()
  get formatted(): string {
    if (!this.street1) {
      return '';
    }
    let address = this.street1 || this.street2;
    address += ', ';
    if (this.postalCode) {
      address += ' ' + this.postalCode;
    }
    if (this.city) {
      address += ' ' + this.city;
    }
    if (this.country) {
      address += ', ' + this.country;
    }
    return address;
  }
}
