import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Materiel } from '@app-models/materiel.model';
import { FormHelperService } from '@app-providers/form-helper.service';
import { Observable, Subject, concat, of } from 'rxjs';
import { tap, switchMap, catchError, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { UserService } from '@app-services/user.service';
import { Article } from '@app-models/article.model';
import { ArticleService } from '@app-services/article.service';
@Component({
  selector: 'app-edit-materiel-modal',
  templateUrl: './edit-materiel-modal.component.html',
  styleUrls: ['./edit-materiel-modal.component.scss'],
})
export class EditMaterielModalComponent implements OnInit {
  public editMode = false;
  public isChangePassword = false;
  public articleForm: FormGroup;
  public materielForm: FormGroup;
  public formSubmitAttempt: boolean;
  isLoading: boolean;
  maxSelectedItems: number;
  step: number = 1;

  selectedArticle: Article;
  articles: Article[];
  searchStr: string;
  articleLoading = false;
  articleInput$ = new Subject<string>();
  constructor(
    private _formHelperService: FormHelperService,
    private _dialogRef: MatDialogRef<EditMaterielModalComponent>,
    private _fb: FormBuilder,
    private _articleService: ArticleService,
    @Inject(MAT_DIALOG_DATA) public data: Materiel,
  ) {
    this.isLoading = false;
    this.articleInput$.pipe(debounceTime(600), distinctUntilChanged()).subscribe((data: any) => {
      if (data) {
        if (typeof data === 'string') {
          this.loadArticle(data);
        } else if (data._id) {
          this.searchStr = data.designation;
          this.selectedArticle = data;
          this.loadStep(3); // material form
        } else if (data.designation && !data._id) {
          // non trouvé
          this.selectedArticle = data;
          this.loadStep(2); // article form
        }
      }
    });
  }

  async ngOnInit() {
    if (this.data && this.data._id) {
      this.selectedArticle = this.data.article;
      this.loadStep(3);
    }
  }

  isFieldValid(form, field: any) {
    return this._formHelperService.isFieldValid(form, field);
  }

  async submit(form) {
    form.updateValueAndValidity();
    this.formSubmitAttempt = true;
    if (form.valid) {
      if (this.step === 2) {
        try {
          this.isLoading = true;
          const inserted: any = await this._articleService.add(new Article(form.value)).toPromise();
          this.selectedArticle = new Article(inserted.data);
          // go to step 3
          // this.loadStep(3); inserer la quantité
          this._dialogRef.close({ ...{ article: this.selectedArticle }, ...form.value });
          this.isLoading = false;
        } catch (e) {
          console.error(e);
          this.isLoading = false;
        }
      } else if (this.step === 3) {
        if (!this.data || !this.data._id) {
          this._dialogRef.close({ ...{ article: this.selectedArticle }, ...form.value });
        } else {
          this.data.quantite = form.value.quantite;
          this._dialogRef.close(this.data);
        }
      }
    } else {
      this._formHelperService.validateAllFormFields(form);
      this.formSubmitAttempt = false;
    }
  }

  close(): void {
    this._dialogRef.close();
  }

  /**
   * [Description] Set error class on form based on Form and field validation
   * @param {FormGroup} form
   * @param {string} field
   */
  displayFieldCss(form: FormGroup, field: string) {
    return {
      'has-error': this._formHelperService.isFieldValid(form, field),
      'has-feedback': this._formHelperService.isFieldValid(form, field),
    };
  }
  async loadArticle(term?) {
    const getQuery = (term) => {
      let queryString =
        "(substringof('" +
        term.split("'").join("''") +
        "',designation) eq true) or (substringof('" +
        term.split("'").join("''") +
        "',codeBarre) eq true) or (substringof('" +
        term.split("'").join("''") +
        "',reference) eq true)";
      return queryString;
    };
    try {
      this.articleLoading = true;
      this.articles = await this._articleService.list(1, 20, getQuery(term)).toPromise();
      this.articleLoading = false;
    } catch (e) {
      this.articleLoading = false;
    }
  }
  loadStep(step) {
    this.step = step;
    if (step === 2) {
      // create article
      this.articleForm = this._fb.group({
        designation: [this.selectedArticle ? this.selectedArticle.designation : '', Validators.required],
        fournisseur: [this.selectedArticle ? this.selectedArticle.fournisseur : ''],
        quantite: [this.data && this.data.quantite ? this.data.quantite : 1, Validators.required],
      });
    }
    if (step === 3) {
      // material form
      this.materielForm = this._fb.group({
        quantite: [this.data && this.data.quantite ? this.data.quantite : 1, Validators.required],
      });
    }
  }
  displayWith(value) {
    if (value) {
      if (typeof value !== 'string') {
        return value.designation;
      } else {
        return value;
      }
    }
  }
}
