import { jsonIgnore } from 'json-ignore';
import { BaseModel } from './base.model';
import { User } from './user.model';
import { Materiel } from './materiel.model';

export class Modele extends BaseModel {
  titre: string;
  dureePrevu: number;
  statut: Modele.StatutEnum;
  responsable: User;
  equipe: User[];
  type: Modele.TypeEnum;
  tag: string;
  description: string;
  compteId: string;
  @jsonIgnore()
  materiel: Materiel[];
  @jsonIgnore()
  get typeLabel(): string {
    return Modele.kindValues.find((i) => i.id === this.type)
      ? Modele.kindValues.find((i) => i.id === this.type).name
      : 'N/A';
  }
  @jsonIgnore()
  get heureFormated(): string {
    if (!this.dureePrevu) {
      return 'N/A';
    }
    const hour = Math.floor(this.dureePrevu);
    const minute = Math.round((this.dureePrevu - hour) * 60);
    return hour + 'h' + minute;
  }
  @jsonIgnore()
  get statutLabel(): string {
    return Modele.statutValues.find((i) => i.id === this.statut).name;
  }
  @jsonIgnore()
  get statutColor(): string {
    return Modele.statutValues.find((i) => i.id === this.statut).color;
  }
  @jsonIgnore()
  get equipeFormatted(): string {
    if (!this.equipe) {
      return '';
    }
    return this.equipe.map((i) => i.prenom).join(', ');
  }
  constructor(arg?: any) {
    super(arg);
    if (arg) {
      if (arg.responsable) {
        this.responsable = BaseModel.initModel(arg.responsable, User);
      }
      if (arg.equipe) {
        this.equipe = arg.equipe.map((i) => BaseModel.initModel(i, User));
      }
      if (arg.materiel) {
        this.materiel = arg.materiel.map((i) => BaseModel.initModel(i, Materiel));
      }
    }
  }
}

export namespace Modele {
  export enum TypeEnum {
    chantier = 'chantier',
    entretien = 'entretien',
    devis = 'devis',
    depannage = 'depannage',
  }
  export const kindValues = [
    { id: Modele.TypeEnum.chantier, name: 'Chantier' },
    { id: Modele.TypeEnum.depannage, name: 'Dépannage' },
    { id: Modele.TypeEnum.entretien, name: 'Entretien' },
    { id: Modele.TypeEnum.devis, name: 'Devis' },
  ];
  export enum StatutEnum {
    aEffectuer = 'aEffectuer',
    enCours = 'enCours',
    termine = 'termine',
    aFacturer = 'aFacturer',
    facture = 'facture',
    regle = 'regle',
  }
  export const statutValues = [
    { id: Modele.StatutEnum.aEffectuer, name: 'A effectuer', color: '#4a90e2' },
    { id: Modele.StatutEnum.enCours, name: 'En cours', color: '#045a4a' },
    { id: Modele.StatutEnum.termine, name: 'Rapport à traiter', color: '#f97a4a' },
    { id: Modele.StatutEnum.aFacturer, name: 'A facturer', color: '#96e83b' },
    { id: Modele.StatutEnum.facture, name: 'Facturé', color: '#96e83b' },
    { id: Modele.StatutEnum.regle, name: 'Reglé', color: '#96e83b' },
  ];
  export const standard = new Modele({
    titre: 'Standard',
    type: Modele.TypeEnum.chantier,
    statut: Modele.StatutEnum.aEffectuer,
    materiel: [],
  });
  export const availableUpdateStatus = {
    /*aEffectuer:  [
      Modele.StatutEnum.enCours
    ]*/
  };
}
