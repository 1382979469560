import { BaseModel } from './base.model';
import { jsonIgnore, jsonIgnoreReplacer } from 'json-ignore';
import { Address } from './address.model';
import { Fichier } from './fichier.model';
import { ValeurLexique } from './valeurLexique.model';
import { Piscine } from './piscine.model';

export class Client extends BaseModel {
  adresse: Address;
  provenance: ValeurLexique;
  noteInterne: string;
  documents: Fichier[];
  objetMetier: Piscine;
  acces: string;

  constructor(arg?: any) {
    super(arg);

    if (arg) {
      if (arg.adresse) {
        this.adresse = BaseModel.initModel(arg.adresse, Address);
      }
      if (arg.documents) {
        this.documents = arg.documents.map((x) => new Fichier(x, 'utilisateur'));
      }
      if (arg.objetMetier) {
        this.objetMetier = BaseModel.initModel(arg.objetMetier, Piscine);
      }
      if (arg.provenance) {
        this.provenance = BaseModel.initModel(arg.provenance, ValeurLexique);
      }
    }
  }
  prepareForSending() {
    const copy: any = {};
    try {
      const data = JSON.parse(JSON.stringify(this, jsonIgnoreReplacer));
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (data[key] === null) {
          } else {
            if (key === 'address' || key === 'objetMetier') {
              copy[key] = this[key].prepareForSending();
            } else {
              copy[key] = data[key];
            }
          }
        }
      }
    } catch (e) {}
    return copy;
  }
}
