export const TRUCATE_LIMIT = 30;
export const TRUCATE_ELLIPSIS = '...';
export const WEEK_DAYS = [
  {
    day: 'lundi',
    isOpen: false,
    startHour: 10,
    startMinute: 0,
    endHour: 13,
    endMinute: 0,
  },
  {
    day: 'lundi',
    isOpen: false,
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 0,
  },
  {
    day: 'mardi',
    isOpen: false,
    startHour: 10,
    startMinute: 0,
    endHour: 13,
    endMinute: 0,
  },
  {
    day: 'mardi',
    isOpen: false,
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 0,
  },
  {
    day: 'mercredi',
    isOpen: false,
    startHour: 10,
    startMinute: 0,
    endHour: 13,
    endMinute: 0,
  },
  {
    day: 'mercredi',
    isOpen: false,
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 0,
  },
  {
    day: 'jeudi',
    isOpen: false,
    startHour: 10,
    startMinute: 0,
    endHour: 13,
    endMinute: 0,
  },
  {
    day: 'jeudi',
    isOpen: false,
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 0,
  },
  {
    day: 'vendredi',
    isOpen: false,
    startHour: 10,
    startMinute: 0,
    endHour: 13,
    endMinute: 0,
  },
  {
    day: 'vendredi',
    isOpen: false,
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 0,
  },
  {
    day: 'samedi',
    isOpen: false,
    startHour: 10,
    startMinute: 0,
    endHour: 13,
    endMinute: 0,
  },
  {
    day: 'samedi',
    isOpen: false,
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 0,
  },
  {
    day: 'dimanche',
    isOpen: false,
    startHour: 10,
    startMinute: 0,
    endHour: 13,
    endMinute: 0,
  },
  {
    day: 'dimanche',
    isOpen: false,
    startHour: 13,
    startMinute: 0,
    endHour: 16,
    endMinute: 0,
  },
];
