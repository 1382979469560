import { jsonIgnore, jsonIgnoreReplacer } from 'json-ignore';
import { BaseModel } from './base.model';
import { Client } from './client.model';
import { Role } from './role.model';

export class User extends BaseModel {
  email: string;
  nom: string;
  prenom: string;
  mailConfirmeLe: string;
  password: string;
  tempPassword: string;
  extra: Client;
  role: Role;
  compteId: string;
  source: string;
  couleur: string;
  telephone: string;
  @jsonIgnore()
  repeatPassword;
  @jsonIgnore()
  checked: boolean;

  constructor(arg?: any) {
    super(arg);

    if (arg) {
      if (arg.extra) {
        this.extra = BaseModel.initModel(arg.extra, Client);
      }
      if (arg.role) {
        this.role = BaseModel.initModel(arg.role, Role);
      }
    }
  }

  @jsonIgnore()
  get name(): string {
    return `${this.prenom || ''} ${this.nom || ''}`;
  }
  @jsonIgnore()
  get avatar(): string {
    return 'https://place-hold.it/30x30&text=' + (this.prenom ? this.prenom[0] : '') + (this.nom ? this.nom[0] : '');
  }

  isValidEmail() {
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return regex.test(this.email);
  }
  prepareForSending() {
    const copy: any = {};
    try {
      const data = JSON.parse(JSON.stringify(this, jsonIgnoreReplacer));
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (data[key] === null) {
          } else {
            if (key === 'extra') {
              copy[key] = this[key].prepareForSending();
            } else {
              copy[key] = data[key];
            }
          }
        }
      }
    } catch (e) {}
    return copy;
  }
}
