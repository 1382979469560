import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from '@app-guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/users',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'demande',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/demande/demande.module').then((m) => m.DemandeModule),
      },
      {
        path: 'materiel',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/materiel/materiel.module').then((m) => m.MaterielModule),
      },
      {
        path: 'nomenclature',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/nomenclature/nomenclature.module').then((m) => m.NomenclatureModule),
      },
      {
        path: 'intervention',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/intervention/intervention.module').then((m) => m.InterventionModule),
      },
      {
        path: 'devis',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/devis/devis.module').then((m) => m.DevisModule),
      },
      {
        path: 'categories',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('../pages/categorie-article/categorie-article.module').then((m) => m.CategorieArticleModule),
      },
      {
        path: 'article',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/article/article.module').then((m) => m.ArticleModule),
      },
      {
        path: 'modele',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/modele/modele.module').then((m) => m.ModeleModule),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'lexicals',
        canActivate: [AuthGuard],
        loadChildren: () => import('../pages/lexicals/lexicals.module').then((m) => m.LexicalsModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('../auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: 'formulaire',
    loadChildren: () => import('../formulaire/formulaire.module').then((m) => m.FormulaireModule),
  },
  {
    path: '**',
    redirectTo: '/users',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
