import { jsonIgnore } from 'json-ignore';
import { BaseModel } from './base.model';
import { User } from './user.model';
import { Materiel } from './materiel.model';
import { Fichier } from './fichier.model';
import { Rapport } from './rapport.model';
import { Modele } from './modele.model';
import { FileUploader, FileItem } from 'ng2-file-upload';
import * as moment from 'moment';

export class Intervention extends Modele {
  numero: string;
  priorite: Intervention.PrioriteEnum;
  titre: string;
  client: User;
  compteId: string;
  photos: Fichier[];
  dateDebutPlanification: string;
  dateFinPlanification: string;
  date: string;
  rapport: Rapport;
  dureeChrono: string[][];
  dureeDisplay: string;
  @jsonIgnore()
  uploader: FileUploader; // for creating
  @jsonIgnore()
  get prioriteIcon(): string {
    const item = Intervention.prioriteValues.find((i) => i.id === this.priorite);
    return item ? item.iconClass : '';
  }
  @jsonIgnore()
  get hasRapport(): boolean {
    return !!(this.rapport && this.rapport._id);
  }
  @jsonIgnore()
  get isLate(): boolean {
    const now = moment();
    let date = moment(this.date);
    date = date.add(this.dureePrevu, 'hours');
    return (!this.rapport || !this.rapport._id) && date.isBefore(now);
  }
  @jsonIgnore()
  get prioriteClass(): string {
    if (this.priorite === Intervention.PrioriteEnum.faible) {
      return 'text-primary';
    }
    if (this.priorite === Intervention.PrioriteEnum.forte) {
      return 'text-danger';
    } else {
      return '';
    }
  }
  @jsonIgnore()
  get isCustomerLocked(): boolean {
    return typeof this.date !== undefined && this.date != null;
  }
  constructor(arg?: any) {
    super(arg);
    if (arg) {
      if (arg.client) {
        this.client = BaseModel.initModel(arg.client, User);
      }

      if (arg.photos) {
        this.photos = arg.photos.map((i) => new Fichier(i, 'intervention'));
      }
      if (arg.materiel) {
        this.materiel = arg.materiel.map((i) => new Materiel(i));
      }
      if (arg.rapport) {
        this.rapport = BaseModel.initModel(arg.rapport, Rapport);
      }
    }
  }
}

export namespace Intervention {
  export enum PrioriteEnum {
    faible = 'faible', // à informer
    normale = 'normale', // accès libre
    forte = 'forte', // vérouillé
  }
  export enum TypeEnum {
    chantier = 'chantier',
    entretien = 'entretien',
    devis = 'devis',
    depannage = 'depannage',
  }
  export const kindValues = [
    { id: Intervention.TypeEnum.chantier, name: 'Chantier' },
    { id: Intervention.TypeEnum.depannage, name: 'Dépannage' },
    { id: Intervention.TypeEnum.entretien, name: 'Entretien' },
    { id: Intervention.TypeEnum.devis, name: 'Devis' },
  ];
  export const prioriteValues = [
    { id: Intervention.PrioriteEnum.faible, name: 'A informer', iconClass: 'phone' },
    { id: Intervention.PrioriteEnum.normale, name: 'Accès libre', iconClass: '' },
    { id: Intervention.PrioriteEnum.forte, name: 'Verrouillé', iconClass: 'lock' },
  ];
  export enum StatutEnum {
    aEffectuer = 'aEffectuer',
    enCours = 'enCours',
    termine = 'termine',
    aFacturer = 'aFacturer',
    facture = 'facture',
    regle = 'regle',
  }
  export const statutValues = [
    { id: Intervention.StatutEnum.aEffectuer, name: 'A effectuer', color: '#4a90e2' },
    { id: Intervention.StatutEnum.enCours, name: 'En cours', color: 'red' },
    { id: Intervention.StatutEnum.termine, name: 'Rapport à traiter', color: '#f97a4a' },
    { id: Intervention.StatutEnum.aFacturer, name: 'A facturer', color: '#96e83b' },
    { id: Intervention.StatutEnum.facture, name: 'Facturé', color: '#96e83b' },
    { id: Intervention.StatutEnum.regle, name: 'Reglé', color: '#96e83b' },
  ];
}
