import { BaseModel } from './base.model';
import { jsonIgnore } from 'json-ignore';
import { User } from './user.model';
import { Prestation } from './prestation.model';
import { Fichier } from './fichier.model';
import { Address } from './address.model';
import * as moment from 'moment';

export class Demande extends BaseModel {
  date: string;
  numero: string;
  type: Demande.TypeEnum;
  statut: Demande.StatutEnum;
  client: User;
  description: string;
  compteId: string;
  photos: Fichier[];
  @jsonIgnore()
  get statutLabel(): string {
    return Demande.statutValues.find((i) => i.id === this.statut).name;
  }
  @jsonIgnore()
  get statutColor(): string {
    return Demande.statutValues.find((i) => i.id === this.statut).color;
  }
  @jsonIgnore()
  get typeLabel(): string {
    return Demande.kindValues.find((i) => i.id === this.type).name;
  }
  constructor(arg?: any) {
    super(arg);

    if (arg) {
      if (arg.client) {
        this.client = new User(arg.client);
      }
      if (arg.photos && arg.photos.length > 0) {
        this.photos = [];
        for (const p of arg.photos) {
          this.photos.push(new Fichier(p, 'demande'));
        }
      }
    }
  }
}
export namespace Demande {
  export enum TypeEnum {
    entretien = 'entretien',
    chantier = 'chantier',
    devis = 'devis',
    autre = 'autre',
    depannage = 'depannage',
  }
  export const kindValues = [
    { id: Demande.TypeEnum.entretien, name: 'Entretien' },
    { id: Demande.TypeEnum.chantier, name: 'Chantier' },
    { id: Demande.TypeEnum.devis, name: 'Devis' },
    { id: Demande.TypeEnum.depannage, name: 'Dépannage' },
    { id: Demande.TypeEnum.autre, name: 'Autre' },
  ];
  export enum StatutEnum {
    nouveau = 'nouveau',
    annule = 'annule',
    termine = 'termine',
    transformeIntervention = 'transformeIntervention',
  }
  export const statutValues = [
    { id: Demande.StatutEnum.nouveau, name: 'Nouveau', color: '#4a90e2' },
    { id: Demande.StatutEnum.annule, name: 'Annulé', color: 'red' },
    { id: Demande.StatutEnum.transformeIntervention, name: 'Transformé en intervention', color: '#f97a4a' },
    { id: Demande.StatutEnum.termine, name: 'Terminé', color: '#96e83b' },
  ];
  export const availableUpdateStatus = {
    nouveau: [Demande.StatutEnum.annule, Demande.StatutEnum.transformeIntervention, Demande.StatutEnum.termine],
    transformeIntervention: [Demande.StatutEnum.annule],
    termine: [],
  };
}
