import { HttpService } from '@app-providers/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseModelInterface, BaseModel } from '../models/base.model';

/**
 * Abstract BaseSevice Class
 *
 * @export
 * @abstract
 * @class BaseService
 * @template T
 */
export abstract class BaseService<T extends BaseModelInterface> {
  /**
   *  Create subjet of items total count
   *
   * @type {BehaviorSubject<number>}
   * @memberof BaseService
   */
  public totalCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public current: BehaviorSubject<T> = new BehaviorSubject<T>(new this.TCreator());

  /**
   *  Create observable of items total count
   *
   * @memberof BaseService
   */
  public totalCount$: any = this.totalCount.asObservable();
  public current$: any = this.current.asObservable();

  constructor(protected httpService: HttpService, protected TCreator: { new (arg?: any): T }, protected path: string) {}

  /**
   * Get one item from API
   *
   * @param {string} id
   * @returns {Observable<T>}
   * @memberof BaseService
   */
  public load(id: string): Observable<T> {
    return this.httpService.get(`${this.path}/${id}`).pipe(
      map((x) => {
        const item = new this.TCreator(x.data);
        this.current.next(item);
        return item;
      }),
    );
  }

  /**
   * Get items from API
   *
   * @param {number} [page=0]
   * @param {number} [perPage=10]
   * @param {*} [query]
   * @param {string} [order]
   * @param {string} [select]
   * @param {boolean} [isAscendant]
   * @returns {Observable<T[]>}
   * @memberof BaseService
   */
  public list(
    page: number = 1,
    perPage: number = 30,
    query?: any,
    order?: string,
    select?: string,
    isAscendant?: boolean,
    noCount?: boolean,
  ): Observable<T[]> {
    const $skip = ((page - 1) * perPage).toString();
    const $top = perPage.toString();
    const $query = { $skip, $top };
    if (query) {
      $query['$filter'] = query;
    }
    if (order) {
      $query['$orderby'] = order;
    }
    if (select) {
      $query['$select'] = select;
    }

    return this.httpService.get(`${this.path}`, $query).pipe(
      map((x) => {
        if (!noCount) {
          this.totalCount.next(x.data.totalDocs);
        }
        return x.data.docs.map((u: T) => {
          return new this.TCreator(u);
        });
      }),
    );
  }

  /**
   * Add item from API
   *
   * @param {T} item
   * @returns {Observable<T>}
   * @memberof BaseService
   */
  public add(item: T): Observable<T> {
    return this.httpService.post(`${this.path}`, item.prepareForSending());
  }

  /**
   * Update item from API
   *
   * @param {T} item
   * @returns {Observable<T>}
   * @memberof BaseService
   */
  public update(item: T): Observable<T> {
    return this.httpService.patch(`${this.path}/${item._id}`, item.prepareForSending());
  }

  /**
   * Delete item from API
   *
   * @param {T} item
   * @returns {Observable<T>}
   * @memberof BaseService
   */
  public delete(item: T): Observable<T> {
    return this.httpService.delete(`${this.path}/${item._id}`);
  }
}
