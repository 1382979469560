import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SessionService } from '@app-providers/session.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  sousdomaine: string;
  constructor(
    private router: Router,
    @Inject(SessionService) private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
    // get sous domaine
    const sousdomaine = this.getSubdomain();
    if (sousdomaine) {
      await this.sessionService.configSousDomaine(sousdomaine);
    } else {
      this.activatedRoute.queryParams.subscribe(async (params) => {
        const sousdomaine = params['sousdomaine'];
        if (sousdomaine) {
          this.sousdomaine = sousdomaine;
          await this.sessionService.configSousDomaine(sousdomaine);
        }
      });
    }
  }
  getSubdomain(): string {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('sousdomaine')) {
      return urlParams.get('sousdomaine');
    }

    let domain = window.location.hostname;
    if (domain.indexOf('.') === -1 || domain.split('.')[0] === 'www') {
      return null;
    } else {
      domain = domain.split('.')[0];
    }
    if (domain.length) {
      return domain.split('-').pop();
    }
  }
}
