import { BaseModel } from './base.model';
import { jsonIgnore, jsonIgnoreReplacer } from 'json-ignore';

export class ValeurLexique extends BaseModel {
  categorie: string;
  valeur: string;
  @jsonIgnore()
  get valeurArray(): string[] {
    if (!this.valeur) {
      return [];
    }
    return this.valeur.split(',');
  }

  constructor(arg?: any) {
    super(arg);

    if (arg && arg.valeur === '') {
      this.valeur = null;
    }
  }
  prepareForSending() {
    const copy: any = {};
    try {
      const data = JSON.parse(JSON.stringify(this, jsonIgnoreReplacer));
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (data[key] === null) {
          } else {
            if (key === 'valeur' && Array.isArray(data[key])) {
              copy[key] = data[key].join(',');
            } else {
              copy[key] = data[key];
            }
          }
        }
      }
    } catch (e) {}
    return copy;
  }
}
