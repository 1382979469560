import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-select-hour-modal',
  templateUrl: './select-hour-modal.component.html',
  styleUrls: ['./select-hour-modal.component.scss'],
})
export class SelectHourModalComponent implements OnInit {
  hour: any;
  isLoading: boolean;
  constructor(
    private _dialogRef: MatDialogRef<SelectHourModalComponent>,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.isLoading = false;
  }

  async ngOnInit() {
    if (this.data) {
      this.hour = { hour: moment(this.data).hour(), minute: moment(this.data).minute() };
    }
  }

  submit() {
    this._dialogRef.close(this.hour);
  }

  close(): void {
    this._dialogRef.close();
  }
  hourChanged(e) {}
}
