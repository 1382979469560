import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@app-models/address.model';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(value: Address) {
    if (value) {
      return `${value.street1 ? value.street1 + ', ' : ''}${value.postalCode} ${value.city}`;
    }
    return value;
  }
}
