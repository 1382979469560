import { jsonIgnore, jsonIgnoreReplacer } from 'json-ignore';
import { BaseModel } from './base.model';
import { User } from './user.model';
import { Article } from './article.model';

export class Materiel extends BaseModel {
  article: Article;
  quantite: number;
  statut: Materiel.StatusEnum;
  technicien: User;
  client: User;
  dateBesoin: string;
  dateDemande: string;
  compteId: string;
  clientId: string | User;
  modeleId: string;
  interventionId: string;
  @jsonIgnore()
  intervention: any;
  @jsonIgnore()
  get statutLabel(): string {
    return Materiel.StatusArrays.find((i) => i._id === this.statut)
      ? Materiel.StatusArrays.find((i) => i._id === this.statut).label
      : 'N/A';
  }
  get statutColor(): string {
    return Materiel.StatusArrays.find((i) => i._id === this.statut)
      ? Materiel.StatusArrays.find((i) => i._id === this.statut).color
      : 'gray';
  }
  constructor(arg?: any, InterventionCreator?: any) {
    super(arg);
    if (arg) {
      if (arg.technicien) {
        this.technicien = BaseModel.initModel(arg.technicien, User);
      }
      if (arg.clientId) {
        this.clientId = BaseModel.initModel(arg.clientId, User);
      }
      if (arg.article) {
        this.article = BaseModel.initModel(arg.article, Article);
      }
      if (arg.interventionId && typeof arg.interventionId !== 'string' && InterventionCreator) {
        this.intervention = new InterventionCreator(arg.interventionId);
      }
    }
  }
}
export namespace Materiel {
  export enum StatusEnum {
    enAttenteFranco = 'enAttenteFranco',
    aCommander = 'aCommander',
    commande = 'commande',
    outillage = 'outillage',
    endOutillage = 'endOutillage',
    aPreparer = 'aPreparer',
    prepare = 'prepare',
    termine = 'termine',
    aFacturer = 'aFacturer',
    facture = 'facture',
  }
  export const StatusArrays = [
    { _id: Materiel.StatusEnum.aPreparer, label: 'A préparer', icon: '', color: '#4a90e2' },
    { _id: Materiel.StatusEnum.aCommander, label: 'A commander', icon: '', color: '#4a90e2' },
    { _id: Materiel.StatusEnum.enAttenteFranco, label: 'En attente', icon: '', color: '#045a4a' },
    { _id: Materiel.StatusEnum.commande, label: 'Commandé', icon: '', color: '#96e83b' },
    { _id: Materiel.StatusEnum.outillage, label: 'Outillage', icon: '', color: '#96e83b', hidden: true },
    // {_id: Materiel.StatusEnum.endOutillage, label: 'fin de l\'outillage', icon: '', color: '#96e83b', hidden: true},
    { _id: Materiel.StatusEnum.prepare, label: 'Preparé', icon: '', color: '#96e83b' },
    { _id: Materiel.StatusEnum.termine, label: 'Terminé', icon: '', color: '#7ed321' },
    { _id: Materiel.StatusEnum.aFacturer, label: 'A facturer', icon: '', color: '#1facff' },
    { _id: Materiel.StatusEnum.facture, label: 'Facturé', icon: '', color: '#135a06' },
  ];
  export const availableUpdateStatus = {
    /*aPreparer:  [
      Materiel.StatusEnum.aCommander
    ],
    aCommander: [
      Materiel.StatusEnum.enAttenteFranco
    ],
    enAttenteFranco: [
      Materiel.StatusEnum.commande
    ]*/
    // termine: []
  };
}
