import { BaseModel } from '../base.model';

export class Profondeur extends BaseModel {
  fondPlat: Number;
  petitBain: Number;
  grandBain: Number;

  constructor(arg?: any) {
    super(arg);
  }
}
