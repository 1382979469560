import { BaseModel } from './base.model';
import { Fichier } from './fichier.model';
import { jsonIgnore } from 'json-ignore';
import * as moment from 'moment';

export class Rapport extends BaseModel {
  clientPresent: boolean;
  commentaire: string;
  tempsPasseSurPlace: string;
  photos: Fichier[];
  @jsonIgnore()
  get tempsPasseSurPlaceValeur(): string {
    const temps = parseFloat(this.tempsPasseSurPlace) * 60 * 60;
    const h = moment.utc(moment.duration(temps, 'seconds').asMilliseconds()).format('H');
    const m = moment.utc(moment.duration(temps, 'seconds').asMilliseconds()).format('mm');
    return h + 'h' + (m && m !== '00' ? m : '');
  }

  constructor(arg?: any) {
    super(arg);
    if (arg.photos) {
      this.photos = arg.photos.map((i) => new Fichier(i, 'intervention'));
    }
  }
}
